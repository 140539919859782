@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  margin: 0;
  padding: 0;
}

:root {
  --primaryClr: #2e5313;
  --secondaryClr: #222a34;
  --headClr: #1a212a;
  --bodyClr: #e1e1e1;
  --whiteClr: #ffffff;
  --blackClr: #000000;
  --lightClr: rgba(255, 255, 255, 0.6);
  --oddsBack: #72bbef;
  --oddsLay: #f994ba;
  --siteRedClr: #af2f2f;
}
.light:root {
  --primaryClr: #ffbd00;
  --secondaryClr: #999;
  --headClr: rgba(0, 0, 0, 0.175);
  --bodyClr: #ddd;
  --whiteClr: #000000;
  --blackClr: #ffffff;
}

html,
body {
  background: var(--bodyClr);
  color: var(--blackClr);
  font-size: 16px;
  // font-family: "Roboto", sans-serif;
  font-family: Averta Std, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  transition: all 0.3s;
}
.btn-primary {
  background: #2e5313;
  color: var(--whiteClr);
  border-color: #2e5313;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 3px;
  padding: 0.425em 1.1875em;
  &:hover,
  &:focus {
    background: #2e5313 !important;
    color: var(--whiteClr) !important;
    border-color: #2e5313 !important;
  }
}
.btn-signin {
  color: var(--blackClr);
  font-size: 14px;
  font-weight: 600;
  border: 0;
  &:hover,
  &:focus {
    color: var(--blackClr) !important;
  }
}
.btn-register {
  color: var(--whiteClr);
  font-size: 14px;
  font-weight: 600;
  padding: 0.6em 0.75em;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 2px 0 rgb(0 0 0 / 12%),
    inset 0 1px rgb(255 255 255 / 4%);
}
.btn-outline-primary {
  border-color: var(--primaryClr);
  &:hover,
  &:focus {
    background: transparent !important;
    color: #000000 !important;
    opacity: 0.9;
    border-color: var(--primaryClr) !important;
  }
}
.btn-outline-secondary {
  font-size: 11px;
  border-radius: 50px;
  padding: 6px 8px;
}
.back-link,
.back_btn {
  color: var(--primaryClr);
  font-size: 12px !important;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.65rem;
  border: 1px solid var(--primaryClr);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s;
  &:hover,
  &:focus {
    background: var(--primaryClr);
    color: var(--blackClr);
  }
}
img {
  width: 100%;
  height: auto;
}
input,
input.form-control {
  border-radius: 0;
  border-color: var(--headClr);
  font-size: 14px;
  font-weight: 300;
  &:focus {
    border-color: var(--headClr);
    box-shadow: none !important;
  }
  &[type="number"] {
    appearance: textfield;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--bodyClr) inset !important;
  -webkit-text-fill-color: var(--whiteClr) !important;
  border-radius: 0 !important;
}
.loader-img {
  background: var(--blackClr);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 150px;
  }
}
.header_open {
  .navbar {
    transform: translateX(0px) !important;
    .navbar-toggler {
      right: -12px !important;
    }
  }
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 25px;
  &:hover {
    background: #a1a1a1;
  }
}
::-webkit-scrollbar-track {
  background: #cbcbcb;
  border-radius: 25px;
}

.header {
  padding: 15px 0;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  background: #ffffff;
  width: 100%;
  transition: all 0.3s;
  box-shadow: #0003 0 4px 6px -1px, #0000001f 0 2px 4px -1px;
  .logo {
    max-width: 125px;
  }
  .header_mnu {
    padding-left: 25px;
    @media screen and (max-width: 991.98px) {
      padding-left: 5px;
    }
    .nav {
      .nav-link {
        color: var(--blackClr);
        font-size: 15px;
        padding: 0.5rem 0.75rem;
        @media screen and (max-width: 991.98px) {
          font-size: 12px;
          padding: 0.5rem 0.5rem;
        }
        svg,
        img {
          width: 18px;
          height: 18px;
          margin-right: 7px;
          fill: var(--primaryClr);
          @media screen and (max-width: 991.98px) {
            width: 14px;
            height: 13px;
            margin-right: 3px;
          }
        }
      }
    }
  }
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-230px);
    width: 230px;
    height: 100%;
    background: #0f212e;
    padding: 1rem;
    z-index: 9;
    transition: all 0.3s;
    .navbar-toggler {
      position: absolute;
      top: 70px;
      right: -30px;
      background: var(--whiteClr);
      padding: 0.15rem 0.25rem;
      border-radius: 25px;
      z-index: 99;
      .navbar-toggler-icon {
        width: 20px;
        height: 22px;
      }
      &:focus {
        outline: 0;
        box-shadow: none;
      }
    }
    .navbar-collapse {
      position: fixed;
      top: 1rem;
      left: 1rem;
      width: 200px;
      height: 100%;
      display: block !important;
      .navbar-nav {
        width: 100%;
        flex-direction: column;
        a {
          color: var(--whiteClr);
          font-size: 1em;
          text-transform: capitalize;
          width: 100%;
          height: 30px;
          line-height: 1.33;
          margin-bottom: 1.1rem;
          padding: 0;
          &:last-child {
            margin-bottom: 0;
          }
          svg,
          img {
            fill: var(--whiteClr);
            width: 20px;
            height: 20px;
            margin-right: 7px;
          }
          &:hover {
            opacity: 0.8;
          }
          &.logoImg {
            width: fit-content;
            margin-bottom: 2.25rem;
            img {
              width: 150px;
              height: auto;
              @media screen and (max-width: 767.98px) {
                width: 120px;
              }
            }
          }
        }
      }
    }
  }
  .headerRight {
    display: flex;
    align-items: center;
    fill: var(--whiteClr);
    .path-stroke {
      stroke: var(--whiteClr);
    }
    .funds ul {
      background: var(--primaryClr);
      border-radius: 25px;
      padding: 4px 12px;
      margin-right: 12px;
      li {
        position: relative;
        a {
          color: var(--whiteClr);
          span {
            padding-left: 8px;
          }
        }
      }
    }

    ul {
      display: flex;
      align-items: center;
      margin-right: 5px;
      li {
        margin: 0 5px;
        position: relative;
        .notification-count {
          width: 12px;
          height: 12px;
          background: var(--siteRedClr);
          color: var(--whiteClr);
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          font-size: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .menu-btn {
      padding: 0;
      background: none;
      border: none;
      &:hover,
      &:focus {
        background: none !important;
        border-color: transparent !important;
      }
      svg {
        width: 20px;
        height: auto;
        margin-left: 5px;
        fill: var(--primaryClr);
      }
    }
  }
}

.back-link {
  font-size: 10px;
  color: var(--primaryClr);
  text-transform: uppercase;
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
  }
}
.offcanvas {
  border: none !important;
  z-index: 9999;
}
.offcanvas-header {
  background: var(--whiteClr);
  color: var(--blackClr);
  box-shadow: rgb(0 0 0 / 83%) 17px 9px 14px 17px,
    rgb(0 0 0 / 64%) 9px 12px 4px -1px;
  .profile-img {
    width: 48px;
    height: 48px;
    background: var(--bodyClr);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    fill: var(--blackClr);
    img {
      width: 26px;
    }
  }
  .user-info {
    color: var(--blackClr);
    font-size: 14px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      font-size: 16px;
      font-weight: bold;
    }
    p {
      margin: 0;
    }
  }
  .btn-close {
    opacity: 1;
    filter: inherit;
  }
}
.offcanvas-body {
  background: var(--bodyClr);
  box-shadow: inset rgb(0 0 0 / 20%) 0 4px 6px -1px,
    rgb(0 0 0 / 12%) 0 2px 4px -1px;
  .balance {
    font-size: 16px;
    color: var(--blackClr);
    display: flex;
    align-items: center;
    svg {
      margin-right: 14px;
      fill: var(--primaryClr);
      stroke: var(--primaryClr);
    }
  }
  .deposit-btn {
    border-radius: 25px;
  }
  .balance-amount {
    text-align: right;
    font-weight: bold;
    font-size: 16px;
    color: var(--blackClr);
  }
  .btn {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
  }
  .menu-items {
    border-top: 1px solid var(--headClr);
    margin-top: 15px;
    li {
      border-bottom: 1px solid var(--headClr);
      a {
        display: flex;
        align-items: center;
        padding: 17px 1rem;
        font-size: 16px;
        color: var(--blackClr);
        svg,
        img {
          margin-right: 15px;
          width: 22px;
          height: auto;
          fill: var(--primaryClr);
          color: var(--primaryClr);
          stroke: var(--primaryClr);
        }
      }
    }
  }
  ul.social_links {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    li {
      margin: 0 10px;
      position: relative;
      svg {
        fill: var(--blackClr);
        stroke: var(--blackClr);
        cursor: pointer;
      }
      .notification-count {
        width: 12px;
        height: 12px;
        background: var(--siteRedClr);
        color: var(--whiteClr);
        border-radius: 50%;
        position: absolute;
        right: -2px;
        top: -2px;
        font-size: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.form-steps {
  text-align: center;
}
.mainBanner {
  .banner_info {
    background: radial-gradient(
      43.89% 132.14% at 50% 43.3%,
      rgb(19, 121, 70) 0%,
      rgb(26, 33, 42) 100%
    );
    width: 100%;
    min-height: 7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding: 2rem;
    border-radius: 12px;
    .payment {
      display: flex;
      li {
        max-height: 36px;
        display: flex;
        align-items: center;
        margin: 0px;
        padding: 0px 1rem;
        img {
          width: 100%;
          max-height: inherit;
          vertical-align: middle;
        }
      }
    }
  }
  .home_banner {
    img {
      border-radius: 0.5rem;
    }
    .slick-dots {
      display: none !important;
    }
    @media screen and (min-width: 768px) {
      // display: none;
    }
  }
  .slick-dots {
    @media screen and (max-width: 767.98px) {
      bottom: -15px;
    }
    bottom: 0px;
    li {
      button {
        &::before {
          color: var(--primaryClr);
          font-size: 13px;
        }
      }
    }
  }
}
.main {
  transition: all 0.3s;
  .TopCategories {
    margin: 0 0px 30px;
    position: relative;
    .casino_headeing {
      position: absolute;
      top: 0;
      left: 0;
      width: 140px;
      height: 70px;
      line-height: 70px;
      background: var(--secondaryClr);
      color: var(--whiteClr);
      text-align: center;
      border-right: 2px solid var(--primaryClr);
    }
    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;
      margin: 0 0 0 140px;
      padding: 0;
      background: var(--secondaryClr);
      list-style: none;
      overflow-x: auto;
      border: 0px;
      margin-bottom: 20px;
      .nav-item {
        button,
        .anav-link {
          background-size: 30px 30px !important;
          background-position: 27px 10px !important;
          background-repeat: no-repeat !important;
          color: var(--whiteClr);
          font-size: 14px;
          width: 92px;
          height: 100%;
          padding: 46px 5px 5px;
          border: 0;
          border-radius: 0;
          &.active {
            background: var(--bodyClr);
          }
          &#uncontrolled-tab-example-tab-Roulette {
            background-image: url("https://gbet567.com/images/Roulette_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Baccarat {
            background-image: url("https://gbet567.com/images/Baccarat_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Andar\ Bahar {
            background-image: url("https://gbet567.com/images/Andar_bahar_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Poker {
            background-image: url("https://gbet567.com/images/Poker_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Blackjack {
            background-image: url("https://gbet567.com/images/Blackjack_icons.png");
          }
          &#uncontrolled-tab-example-tab-TeenPatti {
            background-image: url("https://gbet567.com/images/Teenpatti.png");
          }
          &#uncontrolled-tab-example-tab-32Card {
            background-image: url("https://gbet567.com/images/casino.png");
          }
          &#uncontrolled-tab-example-tab-Seven {
            background-image: url("https://gbet567.com/images/seven.png");
          }
          &#uncontrolled-tab-example-tab-Dices {
            background-image: url("https://gbet567.com/images/dices.png");
          }
          &#uncontrolled-tab-example-tab-Sports {
            background-image: url("https://gbet567.com/images/sports.png");
          }
        }
      }
    }
    .tab-content {
      max-height: 300px;
      overflow: hidden;
      &.view_more {
        max-height: fit-content !important;
      }
    }
  }
  .home_tabs {
    .nav-pills {
      background: #c3c3c3;
      flex-wrap: nowrap;
      overflow-x: auto;
      padding: 5px;
      border-radius: 25px;
      .nav-item {
        min-width: fit-content;
        &:not(:first-child) {
          margin-left: 5px;
        }
        .nav-link {
          background: transparent;
          color: var(--blackClr);
          font-weight: 600;
          font-size: 14px;
          width: fit-content;
          padding: 10px 14px;
          border-radius: 25px;
          &.active {
            border-radius: 25px;
            background: linear-gradient(
              0deg,
              rgb(46 83 19) 0%,
              rgb(145 175 121) 100%
            );
            color: #fff;
          }
        }
      }
    }
    .tab-content {
      .heading {
        font-size: 1.15rem;
      }
    }
    .tabgames {
      margin-top: 1.5rem;
      ul {
        display: grid;
        grid-auto-flow: column;
        padding-top: 10px;
        overflow-x: auto;
        -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        grid-auto-columns: calc(13.33% - 10px);
        gap: 11px;
        padding-bottom: 10px;
        &::-webkit-scrollbar {
          width: 0;
          height: 6px;
        }
        &::-webkit-scrollbar-thumb {
          background: #a1a1a1;
          border-radius: 25px;
          &:hover {
            background: #a1a1a1;
          }
        }
        &::-webkit-scrollbar-track {
          background: #cbcbcb;
          border-radius: 25px;
        }
        @media screen and (max-width: 1299px) and (min-width: 1200px) {
          grid-auto-columns: calc(15.38% - 15px);
          gap: 16px;
        }
        @media screen and (max-width: 1199px) and (min-width: 992px) {
          grid-auto-columns: calc(18.18% - 15px);
          gap: 16px;
        }
        @media screen and (max-width: 991px) and (min-width: 768px) {
          grid-auto-columns: calc(22.22% - 15px);
          gap: 16px;
        }
        @media screen and (max-width: 767px) and (min-width: 576px) {
          grid-auto-columns: calc(25% - 5px);
          gap: 6px;
        }
        @media screen and (max-width: 575px) {
          grid-auto-columns: calc(33.33% - 5px);
          gap: 6px;
        }
        li {
          a {
            display: block;
            img {
              border-radius: 5px;
              box-shadow: 0 4px 6px -1px rgb(27 23 23 / 20%),
                0 2px 4px -1px rgb(0 0 0 / 12%);
            }
          }
        }
      }
      &.allgames {
        ul {
          display: grid;
          grid-gap: 2rem 1rem;
          grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
          grid-auto-flow: row;
          @media screen and (min-width: 1200px) {
            grid-template-columns: repeat(8, 1fr);
            gap: 20px 10px;
          }
          @media screen and (max-width: 1199px) and (min-width: 992px) {
            grid-template-columns: repeat(7, 1fr);
            gap: 30px 15px;
          }
          @media screen and (max-width: 991px) and (min-width: 768px) {
            grid-template-columns: repeat(5, 1fr);
            gap: 30px 15px;
          }
          @media screen and (max-width: 767px) and (min-width: 576px) {
            grid-template-columns: repeat(4, 1fr);
            gap: 10px 5px;
          }
          @media screen and (max-width: 575px) {
            grid-template-columns: repeat(3, 1fr);
            gap: 10px 5px;
          }
        }
      }
    }
  }
  .provider_sec,
  .mob-provider-section {
    margin-top: 1.5rem;
    .heading {
      font-size: 1.15rem;
    }
    ul {
      display: grid;
      grid-auto-flow: column;
      padding-top: 10px;
      overflow-x: auto;
      -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      grid-auto-columns: calc(13.33% - 10px);
      gap: 11px;
      padding: 10px 0;
      @media screen and (max-width: 1299px) and (min-width: 1200px) {
        grid-auto-columns: calc(15.38% - 15px);
        gap: 16px;
      }
      @media screen and (max-width: 1199px) and (min-width: 992px) {
        grid-auto-columns: calc(18.18% - 15px);
        gap: 16px;
      }
      @media screen and (max-width: 991px) and (min-width: 768px) {
        grid-auto-columns: calc(22.22% - 15px);
        gap: 16px;
      }
      @media screen and (max-width: 767px) and (min-width: 576px) {
        grid-auto-columns: calc(25% - 5px);
        gap: 6px;
      }
      @media screen and (max-width: 575px) {
        grid-auto-columns: calc(33.33% - 5px);
        gap: 6px;
      }
      li {
        max-width: 150px;
        height: auto;
        margin: 0 5px 10px;
        img {
          border-radius: 7px;
        }
      }
    }
  }
  .games_tab {
    padding: 20px 0;
    ul {
      display: flex;
      overflow: auto hidden;
      &::-webkit-scrollbar {
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 10px;
      }
      &:hover {
        &::-webkit-scrollbar-thumb {
          background: #a1a1a1;
        }
      }
      li {
        flex: 0 0 auto;
        margin-left: 0.65rem;
        &:first-child {
          margin-left: 0;
        }
        a {
          display: inline-block;
          color: rgb(134, 151, 162);
          position: relative;
          &.active,
          &:hover {
            color: var(--whiteClr);
          }
          &:after {
            position: absolute;
            content: "";
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 10px;
            height: 2px;
            background: var(--primaryClr);
            opacity: 0;
            transition: all 0.3s;
          }
          &.active {
            &:after {
              opacity: 1;
            }
          }
          .imgBox {
            width: 56px;
            height: 56px;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            img {
              background: var(--headClr);
              width: 100%;
              height: auto;
              line-height: 0;
              padding: 1rem;
              border-radius: 25%;
              text-align: center;
              display: inline-block;
              font-size: 1.5rem;
              box-sizing: content-box;
            }
          }
          .text {
            font-size: 11px;
            max-width: 55px;
            white-space: normal;
            text-align: center;
            line-height: 1rem;
            margin-top: 0.5rem;
            letter-spacing: -0.1px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .banner_sec {
    padding: 1.5rem 0;
    .banner_imgboxes {
      display: flex;
      align-items: center;
      overflow-x: auto;
      &::-webkit-scrollbar {
        width: 0;
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background: #a1a1a1;
        border-radius: 25px;
        &:hover {
          background: #a1a1a1;
        }
      }
      &::-webkit-scrollbar-track {
        background: #cbcbcb;
        border-radius: 25px;
      }
      .img_box {
        flex: 0 0 33.33%;
        height: auto;
        padding: 0 8px 1rem;
        img {
          border-radius: 0.5rem;
        }
      }
    }
    @media screen and (max-width: 767.98px) {
      display: none;
    }
  }
  .sectionTitle {
    font-size: 18px;
    font-weight: normal;
    color: var(--whiteClr);
    position: relative;
    display: inline-block;
    margin: 20px 0 10px;
    padding-bottom: 5px;
    &:after {
      content: " ";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 50%;
      height: 1px;
      background: var(--primaryClr);
    }
  }
  .innerBanner {
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    .link {
      width: 100%;
      background: var(--primaryClr);
      font-size: 11px;
      font-weight: 500;
      display: block;
      text-align: center;
      color: var(--whiteClr);
      text-transform: uppercase;
      padding: 8px;
    }
  }
  .payment-info {
    background: #c3c3c3;
    margin: 0 -1rem;
    padding: 22px 1rem;
    color: var(--blackClr);
    .balance {
      font-size: 16px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 14px;
        fill: var(--blackClr);
        stroke: var(--blackClr);
        opacity: 0.6;
      }
    }
    .balance-amount {
      text-align: right;
      font-weight: bold;
      font-size: 16px;
    }
    .btn {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      border-radius: 55px;
      &.btn-outline-secondary {
        border-color: #6c757d;
        color: #6c757d;
      }
      &:hover {
        color: #fff;
      }
    }
  }
  .account-statement {
    h3 {
      border-bottom: 1px solid var(--blackClr);
      font-size: 16px;
      font-weight: 300;
      margin: 12px -1rem 22px -1rem;
      padding: 0 1rem 12px 1rem;
    }
    .btn-primary {
      border-radius: 0;
      padding: 6px 10px;
    }
    ul {
      margin: 0 -1rem;
      border-top: 1px solid var(--blackClr);
      li {
        padding: 22px 1rem;
        border-bottom: 1px solid var(--blackClr);
        .h4 {
          font-size: 14px;
          margin-bottom: 0;
        }
        .h5 {
          margin-bottom: 0;
          font-size: 12px;
          letter-spacing: 0.86px;
        }
        .details {
          font-size: 12px;
          font-weight: 300;
        }
        .statue {
          font-size: 10px;
          font-weight: 300;
          letter-spacing: 0.7px;
        }
        small {
          margin-top: 10px;
          font-size: 10px;
          font-weight: 300;
          display: block;
        }
      }
    }
  }
  .profile-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: 10px;
    img {
      max-width: 60px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 2px solid var(--primaryClr);
    }
    span {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .edit-btn {
    fill: var(--headClr);
    align-items: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    position: relative;
    right: -25px;
    top: 61px;
    button {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      display: block;
      padding: 6px;
    }
  }
  .profile-info {
    th {
      // background: var(--headClr);
      color: var(--blackClr);
      border-color: #c3c3c3;
      background: #c3c3c3;
    }
    td {
      background: var(--bodyClr);
      color: var(--blackClr);
      border-color: #1a212a;
    }
    .edit-btn {
      top: 0;
      right: 0;
      margin-left: auto;
    }
    button {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
  }
}
.listings {
  .filter {
    margin-bottom: 20px;

    .dropdown-toggle {
      background: var(--primaryClr) !important;
      border: none !important;
      border-radius: 0;
      padding: 5px 15px;
    }
    .btn-outline-primary {
      width: 100%;
      padding: 5px;
      background: transparent;
      border-radius: 0 !important;
    }
    .dropdown-menu {
      border-radius: 0;
      border: 1px solid var(--whiteClr);
      width: 100% !important;
      white-space: pre-wrap;
      min-width: auto;
      padding: 0 !important;
      margin-top: -3px;
      a {
        border-bottom: 1px solid var(--whiteClr);
        color: var(--headClr);
        font-size: 14px;
        opacity: 0.8;
        padding: 13px 18px;
        &:last-child {
          border: none;
        }
      }
    }
  }
  .like-btn {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.4);
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 9px;
    border-radius: 0 0 7px 0;
    display: none;
    svg {
      fill: var(--whiteClr);
    }
  }
}
.steps-canvas {
  .offcanvas-header {
    // background: #071d2a;
    .offcanvas-title {
      font-size: 20px;
      font-weight: bold;
      // color: var(--whiteClr);
    }
  }
  .form-steps {
    padding: 55px 30px 0;
    .h2 {
      font-size: 28px;
      font-weight: 500;
      color: var(--blackClr);
    }
    p {
      font-size: 18px;
      font-weight: 300;
      color: var(--blackClr);
    }
    input {
      background: var(--headClr);
      border-radius: 10px;
      border: 1px solid !important;
      border-color: var(--headClr) !important;
      padding: 14px 20px;
      color: var(--whiteClr);
    }
    .icon {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(0, -50%);
      fill: var(--whiteClr);
    }
    .flag-dropdown {
      background: transparent;
      border: 0;
      border-right: 1px solid var(--headClr);
      &.open,
      &:focus {
        background: transparent;
      }
      .selected-flag {
        background: transparent !important;
      }
      .country-list {
        background: var(--bodyClr) !important;
        overflow-y: auto;
        width: 308px;
        margin: 10px 0;
        text-align: left;
        @media only screen and (max-width: 767.98px) {
          width: 268px;
        }
        .country {
          display: none;
          &:first-child {
            display: block !important;
          }
          &.highlight,
          &:hover {
            background: var(--headClr) !important;
          }
        }
      }
    }
    .btn {
      padding: 9px 30px;
    }
    .form-link {
      font-size: 14px;
      color: var(--lightClr);
      text-decoration: underline;
      &:hover {
        opacity: 0.7;
      }
    }
    .sign-up {
      font-size: 14px;
      font-weight: 500;
      a {
        font-size: 16px;
        color: var(--primaryClr);
        text-decoration: underline;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
.is-invalid ~ span.icon {
  top: 32% !important;
}
.tabs-area {
  margin-top: 10px;
  ul {
    flex-wrap: nowrap;
    li {
      width: 100%;
      &:not(:last-child) {
        border-right: 1px solid var(--whiteClr);
      }
      .nav-link {
        width: 100%;
        background: #c3c3c3;
        color: rgb(0 0 0);
        border-radius: 0;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 17px 10px;
        border: none;
        &.active {
          background: linear-gradient(
            0deg,
            rgb(46, 83, 19) 0%,
            rgb(145, 175, 121) 100%
          );
          color: #fff;
        }
      }
    }
  }
  .tab-content {
    .bank-details {
      margin-top: 22px;
      background: #c3c3c3;
      box-shadow: 5px 5px 10px #0000001a;
      border-radius: 10px;
      overflow: hidden;
      .top-area {
        background: #9d9d9d;
        width: 100%;
        font-size: 14px;
        padding: 20px;
      }
      .bottom-area {
        padding: 20px;
        table {
          margin-bottom: 0;
          th {
            color: var(--blackClr);
            font-size: 18px;
            font-weight: 500;
            padding: 5px 0;
          }
          td {
            color: var(--blackClr);
            font-size: 14px;
            font-weight: 300;
            padding: 5px 0;
            width: 50%;
          }
        }
      }
    }
    .upi-list {
      margin-top: 40px;
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        li {
          margin: 0 7px;
          width: 32px;
          border: none;
        }
      }
    }
    form {
      padding: 55px 30px 0;
      text-align: center;
      input {
        background: var(--headClr);
        border-radius: 10px;
        border-color: var(--headClr);
        padding: 14px 20px;
        &.form-check-input {
          border-radius: 50px;
          width: 24px;
          height: 24px;
          border: 2px solid var(--primaryClr);
          padding: 0;
          background-color: var(--whiteClr);
          position: relative;
          &:checked:after {
            content: "";
            background: var(--headClr);
            border-radius: 50px;
            width: 16px;
            height: 16px;
            display: block;
            position: absolute;
            top: 2px;
            left: 2px;
          }
        }
      }
      .btn {
        padding: 9px 30px;
        font-size: 14px;
        text-transform: uppercase;
        min-width: 120px;
      }
      .radio-btns {
        display: flex;
        .form-check {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 300;
          .form-check-label {
            margin-left: 5px;
            margin-top: 3px;
          }
        }
      }
    }
    .card-holder {
      height: 40px;
      background: var(--whiteClr);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      box-shadow: 5px 5px 10px #0000001a;
      img {
        max-width: 100px;
      }
    }
  }
  .h4 {
    font-size: 14px;
    font-weight: 500;
  }
  .sectionTitle {
    &::after {
      background: var(--headClr);
      width: 100%;
    }
  }
}
.select_amnt {
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 15px;
    li {
      width: auto;
      border-right: 0 !important;
      &:not(:last-child) {
        margin-right: 8px;
      }
      a {
        border: 1px solid var(--primaryClr);
        color: var(--blackClr);
        display: inline-block;
        padding: 0.25rem 0.65rem;
        transition: all 0.2s;
        border-radius: 3px;
        &:hover,
        &:focus {
          background: var(--primaryClr);
          color: var(--bodyClr);
        }
      }
    }
  }
}
.custom-tabs {
  padding-top: 10px;
  .sports-name-listing {
    ul {
      flex-wrap: nowrap;
      padding: 10px;
      background: var(--secondaryClr);
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      li {
        margin: 0 5px;
        a,
        div {
          color: #fff;
          padding: 5px;
          display: block;
          cursor: pointer;
          &:hover,
          &.active {
            background: transparent;
            color: var(--primaryClr);
          }
        }
      }
    }
  }
  .nav {
    flex-wrap: nowrap;
    background: var(--secondaryClr);
    margin-bottom: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;
    .nav-item {
      margin: 0 5px;
      .nav-link {
        border-radius: 0;
        font-size: 12px;
        padding: 5px;
        color: var(--whiteClr);
        font-weight: 500;
        border: none;
        &:hover,
        &.active {
          background: transparent;
          color: var(--primaryClr);
        }
      }
    }
  }
  .tab-pane {
    .title {
      background: var(--headClr);
      margin: 0 0 10px;
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      span {
        font-size: 14px;
        padding: 6px 1rem;
        display: inline-block;
        background: var(--primaryClr);
        color: var(--blackClr);
        margin-left: -1rem;
      }
    }
    .game-listing {
      .breadcrumb {
        font-size: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 10px;
          color: #838383;
        }
      }
      .status {
        background: var(--primaryClr);
        color: var(--blackClr);
        display: flex;
        padding: 1px 5px;
        font-size: 9px;
        border-radius: 2px;
        align-items: center;
        justify-content: center;
      }
      .listing {
        display: flex;
        flex-wrap: nowrap;
        li {
          margin: 0 2px;
          width: 100%;
          .info-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: var(--blackClr);
            font-size: 10px;
            border-radius: 2px;
            padding: 2px 4px;
            cursor: pointer;
            span {
              font-size: 12px;
            }
            &.yes {
              background: var(--oddsBack);
            }
            &.no {
              background: var(--oddsLay);
            }
          }
        }
      }
    }
    .top-row {
      display: flex;
      justify-content: space-between;
      background: var(--headClr);
      margin: 20px -1rem;
      padding: 0 1rem;
      .bet-btns {
        display: flex;
        li {
          background: var(--primaryClr);
          color: var(--whiteClr);
          margin: 4px 0px 4px 4px;
          text-transform: uppercase;
          font-size: 11px;
          padding: 4px 8px;
          border-radius: 2px;
        }
      }
    }
    .status-btns {
      display: flex;
      align-items: center;
      .info-block {
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--whiteClr);
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
      }
    }
    .game-points {
      ul {
        margin: 0 -1rem;
        li {
          display: flex;
          border-bottom: 1px solid var(--secondaryClr);
          padding: 3px 1rem;
          align-items: center;
          font-size: 11px;
          .team-name {
            display: flex;
            flex-direction: column;
            line-height: normal;
            .time {
              color: var(--siteRedClr);
            }
          }
          .score {
            margin-left: auto;
          }
          .status-btns {
            margin-left: 5px;
            position: relative;
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 8px;
              line-height: normal;
              span {
                font-size: 12px;
              }
            }
            .points-status {
              position: absolute;
              top: 4px;
              left: 4px;
              width: 95%;
              background: rgba(0, 0, 0, 0.7);
              font-size: 12px;
              color: var(--whiteClr);
              text-align: center;
              border-radius: 2px;
              height: 80%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .game-top-area {
      background: var(--headClr);
      margin: 0 -1rem;
      padding: 10px 1rem;
      .date {
        text-align: center;
        color: var(--primaryClr);
        font-size: 12px;
        margin-bottom: 5px;
      }
      .title {
        margin: 0;
        padding: 0;
        font-size: 14px;
      }
      .range {
        background: var(--primaryClr);
        font-size: 11px;
        color: var(--blackClr);
        padding: 2px 5px;
        line-height: normal;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.page-details {
  .top-row {
    display: flex;
    justify-content: space-between;
    background: var(--headClr);
    margin-bottom: 10px;
    padding: 0.5rem 1rem;
    ul.bet-btns {
      display: flex;
      li {
        background: var(--primaryClr);
        color: var(--blackClr);
        margin: 4px 0px 4px 4px;
        text-transform: uppercase;
        font-size: 11px;
        padding: 4px 8px;
        border-radius: 2px;
      }
    }
  }
  .game-top-area {
    background: var(--headClr);
    margin-bottom: 10px;
    padding: 10px 1rem;
    .date {
      text-align: center;
      color: var(--primaryClr);
      font-size: 12px;
      margin-bottom: 5px;
    }
    .title {
      margin: 0;
      padding: 0;
      font-size: 14px;
    }
    .range {
      background: var(--primaryClr);
      font-size: 11px;
      color: var(--blackClr);
      padding: 2px 5px;
      line-height: normal;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .title {
    background: var(--headClr);
    margin-bottom: 10px;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    span {
      font-size: 14px;
      padding: 6px 1rem;
      display: inline-block;
      background: var(--primaryClr);
      color: var(--blackClr);
      margin-left: -1rem;
    }
    .status-btns {
      display: flex;
      align-items: center;
      .info-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 8px;
        line-height: normal;
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--blackClr);
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
      }
    }
  }
  .game-points {
    ul {
      li {
        display: flex;
        border-bottom: 1px solid var(--secondaryClr);
        padding: 3px 0 3px 0.75rem;
        align-items: center;
        .score {
          margin-left: auto;
        }
        .team-name {
          display: flex;
          flex-direction: column;
          line-height: normal;
          .time {
            color: var(--siteRedClr);
          }
        }
        .status-btns {
          display: flex;
          align-items: center;
          position: relative;
          .points-status {
            position: absolute;
            top: 4px;
            left: 4px;
            width: 95%;
            background: rgba(0, 0, 0, 0.7);
            font-size: 12px;
            color: var(--whiteClr);
            text-align: center;
            border-radius: 2px;
            height: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .info-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 8px;
          line-height: normal;
          font-size: 10px;
          margin-left: 4px;
          border-radius: 2px;
          padding: 5px 2px;
          width: 60px;
          text-align: center;
          color: var(--blackClr);
          cursor: pointer;
          &.yes {
            background: var(--oddsBack);
          }
          &.no {
            background: var(--oddsLay);
          }
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.footer {
  background: #c3c3c3;
  padding: 30px 18px 80px 18px;
  margin-top: 20px;
  transition: all 0.3s;
  p {
    color: var(--blackClr);
    opacity: 0.6;
    font-size: 15px;
    line-height: 24px;
    font-weight: 300;
  }
  .footer-logo {
    max-width: 150px;
    margin-bottom: 20px;
  }
  .paymentImages,
  .secureGaming {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        padding: 8px;
        img {
          max-height: 35px;
          filter: grayscale(1);
          transition: all 0.2s;
          &:hover {
            filter: none;
          }
        }
      }
    }
  }
  .footer-links {
    background: #fff;
    display: inline-block;
    margin: 20px auto 10px auto;
    padding: 0 15px;
    width: 100%;
    ul {
      text-align: center;
      padding: 15px 0;
      li {
        font-size: 12px;
        font-weight: 300;
        display: inline-block;
        position: relative;
        margin: 5px 15px;
        &:after {
          content: " ";
          position: absolute;
          right: -16px;
          top: 2px;
          width: 1px;
          height: 14px;
          background: var(--headClr);
        }
        &:last-child {
          margin-right: 0;
          &::after {
            display: none;
          }
        }
        a {
          color: var(--blackClr);
          font-size: 15px;
          font-weight: 400;
        }
      }
    }
  }
  .social-icons {
    padding-top: 25px;
    ul {
      display: flex;
      justify-content: center;
      li {
        margin-right: 15px;
        position: relative;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 40px;
          height: auto;
          cursor: pointer;
        }
        svg {
          width: 32px;
        }
        .notification-count {
          width: 12px;
          height: 12px;
          background: var(--siteRedClr);
          color: var(--whiteClr);
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          font-size: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .footer-menu {
    background: #c3c3c3;
    box-shadow: 0 0px 14px rgb(255 189 0 / 12%);
    ul {
      li {
        a {
          padding: 15px 5px 10px 5px;
          color: var(--whiteClr);
          font-size: 11px;
          font-weight: 700;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-decoration: none;
          cursor: pointer;
          svg,
          img {
            fill: currentColor;
            stroke: currentColor;
            max-width: 16px;
            max-height: 16px;
            filter: brightness(0.5);
          }
          span {
            margin-top: 5px;
          }
          &.active {
            color: var(--primaryClr);
            svg {
              fill: currentColor;
              stroke: currentColor;
            }
          }
        }
      }
    }
  }
}
.agent-head {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  margin-top: 15px;
  .social-icon {
    width: 12%;
    margin: 0 2%;
    img {
      width: 100%;
    }
  }
}
.live_casino_page {
  // padding-top: 50px;

  // &.ezugi {
  //   background: rgb(35, 31, 32);
  // }
  &.live {
    background: var(--headClr);
    color: var(--whiteClr);
  }

  .header-bg {
    // background-color: #e3e2e2;
    .header {
      margin-bottom: 0;
    }

    .headerRight {
      fill: var(--primaryClr);
      .path-stroke {
        stroke: var(--primaryClr);
      }
    }
  }

  // @media screen and (min-width: 750px) {
  //   padding-top: 60px;
  // }

  // @media screen and (min-width: 1200px) {
  //   padding-top: 70px;
  // }

  scroll-snap-type: mandatory;

  .custom_placeholder_parent {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba(87, 84, 85, 0.84);

    .custom_placeholder_child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 100px;
    }
  }
}
.form-control {
  background: transparent !important;
  color: var(--blackClr) !important;
  &:focus {
    border-color: var(--primaryClr);
  }
}
.section-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  h2 {
    margin-bottom: 0 !important;
  }
  a {
    background: var(--primaryClr);
    color: var(--blackClr);
    font-size: 15px;
    font-weight: 600;
    font-family: sans-serif;
    height: 40px;
    margin-left: 8px;
    padding: 10px 20px;
    border: 0;
    border-radius: 25px;
    transition: all 0.4s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
}

.betslip-content.inline_betslip {
  padding: 0.5rem;
  border: 1px solid #c5c5c5;
  box-shadow: 0 2px 4px rgba(255, 189, 0, 0.12);
  .stack-win-value {
    display: flex;
    margin-bottom: 5px;
    > div {
      width: calc(33.33% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }

    .odds,
    .stack_field_value {
      .form-control {
        border-color: var(--secondaryClr);
      }
    }
  }
  .winning p {
    line-height: 1.3;
    margin-bottom: 0;
  }
  .auto-stack {
    margin-bottom: 10px;
    ul {
      display: flex;
      align-items: center;
      li {
        width: 20%;
        margin-right: 0.5rem;
        &:last-child {
          margin-right: 0;
        }
        .stack-btn {
          background: #c3c3c3;
          color: var(--blackClr);
          width: 100%;
        }
      }
    }
  }
  .btn_group {
    display: flex;
    > div {
      width: calc(50% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    .bet-now {
      .place-btn {
        background: var(--primaryClr);
        color: var(--whiteClr);
        font-size: 1.15rem;
        font-weight: 600;
        width: 100%;
      }
    }
    .cancel-btn {
      background: var(--siteRedClr);
      color: var(--blackClr);
      font-size: 1.15rem;
      font-weight: 600;
      width: 100%;
    }
  }
}

.live_dealer_casino {
  padding: 20px 0;
  h4 {
    color: var(--whiteClr);
    margin-bottom: 10px;
  }
  .casino_games {
    ul {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(6, 1fr);
      li {
        a {
          img {
            width: 100%;
            height: auto;
            border-radius: 0.5rem;
          }
        }
      }
    }
  }
}

.casino_games_type {
  padding: 20px 0;
  .listings {
    margin-bottom: 30px;
    .text {
      margin: 0 0 10px;
    }
    .Evolution_Games {
      display: flex;
      justify-content: start;
      overflow-x: auto;
      padding-bottom: 5px;
      scrollbar-color: var(--headClr) var(--secondaryClr);
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background: var(--secondaryClr);
      }
      &::-webkit-scrollbar-thumb {
        background: var(--headClr);
        &:hover {
          background: var(--headClr);
        }
      }
      .casino_games {
        width: calc(12.5% - 10.5px);
        min-width: calc(12.5% - 10.5px);
        margin-right: 12px;
        &:last-child {
          margin-right: 0;
        }
        .shadow.rounded.position-relative {
          box-shadow: 0px 0px 0px var(--blackClr) !important;
          text-align: center;
          img {
            width: 100%;
            height: auto;
            border-radius: 12px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .header_open {
    .header,
    .main,
    .footer,
    .iframe_sec,
    .ezugi_iframe {
      // width: calc(100% - 230px);
      // margin-left: 230px;
      transition: all 0.3s;
    }
  }
}

@media only screen and (max-width: 1199.98px) {
  .casino_games_type .listings .Evolution_Games .casino_games {
    width: calc(16.67% - 10px);
    min-width: calc(16.67% - 10px);
  }
  .main .TopCategories .tab-content {
    max-height: 330px;
  }
  .live_dealer_casino {
    .casino_games {
      ul {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
}

@media only screen and (max-width: 991.98px) {
  .mainBanner {
    .banner_info {
      flex-wrap: wrap;
      margin-top: 0;
      margin-bottom: 0.5rem;
      padding: 1rem;
      h2 {
        order: 0;
        font-size: 1rem;
      }
      .payment {
        order: 2;
        width: 100%;
        justify-content: center;
        margin-top: 1rem;
      }
      .btn-primary {
        order: 1;
      }
    }
    @media screen and (max-width: 767.98px) {
      padding: 1.5rem 0;
    }
  }
  .main .banner_sec .banner_imgboxes .img_box {
    flex: 0 0 50%;
    padding: 0 8px 0.5rem;
  }
  .live_dealer_casino {
    .casino_games {
      ul {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
  .casino_games_type {
    .listings {
      .Evolution_Games {
        .casino_games {
          width: calc(25% - 9px);
          min-width: calc(25% - 9px);
        }
      }
    }
  }
}

@media only screen and (max-width: 767.98px) {
  .cards {
    width: 100% !important;
  }
  .primary_card .stats-card {
    display: grid !important;
    ul {
      width: 100% !important;
      border-right: 0 !important;
    }
  }
  .valuesDeposit {
    align-items: center;
    h3 {
      font-size: 16px;
    }
    .copyValue {
      font-size: 15px !important;
    }
  }
  .primary_card .stats-card ul li {
    color: #fff;
  }
  .main {
    .TopCategories {
      .tab-content {
        max-height: 250px;
      }
    }
    .games_tab {
      ul {
        &::-webkit-scrollbar-thumb {
          background: rgb(76, 81, 89);
        }
      }
    }
  }

  .main .banner_sec .banner_imgboxes .img_box {
    flex: 0 0 100%;
  }

  .agent-head {
    justify-content: space-between;
    .social-icon {
      width: 40%;
      margin: 2%;
    }
  }
  .header {
    padding: 10px 0 !important;
    background: rgb(212, 123, 57);
    &.aftrlgn {
      .logo {
        margin-left: 35px;
      }
    }
    .navbar {
      .navbar-toggler {
        top: 18px;
        right: -37px;
        padding: 0 0.15rem;
        line-height: 16px;
        border-radius: 0.375rem;
        .navbar-toggler-icon {
          height: 20px;
          width: 18px;
        }
      }
    }
    .headerRight {
      .menu-btn {
        margin-left: 0;
      }
      .funds ul {
        margin-right: 0px;
        padding: 0px !important;
        background: inherit !important;
        li {
          margin: 0 4px !important;
          a {
            color: var(--whiteClr);
            background: var(--primaryClr);
            display: block;
            border-radius: 22px;
            padding: 3px 10px !important;
            span {
              padding-left: 2px;
            }
          }
        }
        li:after {
          display: none;
        }
      }
      ul {
        li {
          margin: 0 2px;
        }
      }
    }
  }
  .tabs-area .tab-content .card-holder img {
    max-width: 80px;
  }
  .custom-tabs {
    padding-top: 0px;
  }
  .custom-tabs .sports-name-listing ul {
    padding: 5px;
    margin-bottom: 5px;
  }
  .page-details .game-points ul li {
    display: block;
    padding-left: 0;
  }
  .page-details .game-points ul li .team-name {
    width: 100%;
    padding-left: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .page-details .game-points ul li .status-btns {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .page-details .game-points ul li .info-block {
    width: inherit;
    &:first-child {
      margin-left: 0;
    }
  }
  .casino_games_type {
    .listings {
      .Evolution_Games {
        .casino_games {
          width: calc(33.33% - 8px);
          min-width: calc(33.33% - 8px);
        }
      }
    }
  }
  .ezugi_iframe {
    iframe {
      padding-top: 0 !important;
    }
  }
}

@media only screen and (max-width: 575px) {
  .main {
    .TopCategories {
      .tab-content {
        max-height: 365px;
      }
    }
  }
  .live_dealer_casino {
    .casino_games {
      ul {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media only screen and (max-width: 521px) {
  .main {
    .TopCategories {
      .tab-content {
        max-height: 330px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .casino_games_type {
    .listings {
      .Evolution_Games {
        .casino_games {
          width: calc(50% - 6px);
          min-width: calc(50% - 6px);
        }
      }
    }
  }
  .main {
    .TopCategories {
      .tab-content {
        max-height: 290px;
      }
    }
  }
}

@media only screen and (max-width: 421px) {
  .main {
    .TopCategories {
      .tab-content {
        max-height: 265px;
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .header {
    .headerRight {
      .funds {
        ul {
          li {
            a {
              display: flex;
              border-radius: 22px;
              font-size: 12px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .main {
    .TopCategories {
      .tab-content {
        max-height: 250px;
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .main {
    .TopCategories {
      .tab-content {
        max-height: 240px;
      }
    }
  }
}
.main .account-statement button.calender-icon svg {
  opacity: 0;
  fill: #fff;
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .main .account-statement button.calender-icon svg {
    opacity: 1;
  }
}
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.main .account-statement input {
  padding-right: 7px;
  position: relative;
  z-index: 9 !important;
}
.main .account-statement button.calender-icon {
  position: absolute;
  right: 0;
}
.changePasswordModal {
  .modal-content {
    background: var(--bodyClr);
    .modal-header {
      button {
        background: transparent url(../public/images/cross-23.svg) center/1em
          auto no-repeat;
      }
    }
  }
}
.is-invalid ~ .icon {
  top: 32% !important;
}
.contact-section {
  h3 {
    color: #000;
    margin-bottom: 26px;
    margin-top: 15px;
  }
  .contact-content {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 767px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    .contact-one {
      background: rgb(195 195 195 / 65%);
      border-radius: 5px;
      display: flex;
      justify-content: center;
      padding: 12px 8px;
      margin-right: 20px;
      flex-direction: column;
      align-items: center;
      color: #616161;
      @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
        margin-right: 0;
      }
      p {
        margin: 0;
        padding-top: 12px;
        font-size: 16px;
        font-weight: bold;
        white-space: nowrap;
      }
    }
    img {
      width: 60px;
      height: 60px;
    }
  }
}
.privacy_policy {
  padding-bottom: 71px;
  h3 {
    color: #000;
    margin-bottom: 26px;
    margin-top: 15px;
  }
}

// sportsbook css
.main {
  .leftbarSec {
    background: var(--whiteClr);
    width: 200px;
    height: calc(100vh - 52px);
    overflow-y: auto;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 4px 6px -1px,
      rgba(0, 0, 0, 0.1215686275) 0 2px 4px -1px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    @media only screen and (max-width: 1199.98px) {
      position: fixed;
      transform: translateX(-100%);
      transition: all 0.3s;
      z-index: 999;
    }
    @media only screen and (max-width: 767.98px) {
      width: 70%;
    }
    .games_link {
      h6 {
        color: var(--primaryClr);
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 5px 10px;
        border-bottom: 1px solid var(--primaryClr);
      }
      ul {
        padding: 10px 0;
        li {
          cursor: pointer;
          border-bottom: 1px solid #333;
          > div {
            line-height: 35px;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            position: relative;
            &:before {
              position: absolute;
              content: "";
              top: 0;
              left: -10px;
              width: 2px;
              height: 100%;
              background: var(--primaryClr);
              opacity: 0;
              transition: all 0.3s;
            }
            &:hover {
              &:before {
                opacity: 1;
                left: 0;
              }
            }
            img {
              width: 20px;
              height: auto;
              filter: invert(1);
            }
            span {
              color: var(--blackClr);
              font-size: 12px;
              font-weight: 600;
              text-transform: uppercase;
              margin-left: 10px;
              opacity: 0.8;
            }
            .sport-event-count {
              background: var(--primaryClr);
              color: var(--blackClr);
              font-size: 12px;
              margin-left: auto;
              padding: 0.25em 0.4em;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  .middleContentSec {
    width: 100%;
    @media only screen and (min-width: 1200px) {
      width: calc(100% - 600px);
      max-height: calc(100vh - 63px);
      overflow-y: auto;
      padding: 0 5px;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
        width: calc(100% - 500px);
      }
    }
  }
  .rightbarSec {
    background: var(--whiteClr);
    width: 400px;
    height: calc(100vh - 52px);
    overflow-y: auto;
    padding: 10px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
      width: 300px;
    }
    @media only screen and (max-width: 1199.98px) {
      display: none;
    }
    h6 {
      color: var(--primaryClr);
      font-size: 12px;
      font-weight: 600;
      line-height: 28px;
      padding: 5px 10px;
      border-bottom: 1px solid var(--primaryClr);
    }
    .VSlider {
      background-color: #e1e1e1;
      border-radius: 10px;
      padding: 20px 10px;
      margin: 14px 0;
      .slick-slider {
        position: relative;
        .slide_box {
          width: 70%;
          margin: 0 auto;
          position: relative;
          img {
            border-radius: 5px;
          }
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.45);
            border-radius: 5px;
          }
        }
        .slick-dots {
          top: 40%;
          bottom: auto;
          right: -40px;
          left: auto;
          transform: rotate(90deg);
          width: auto;
          li {
            width: 32px;
            height: 32px;
            transform: rotate(-90deg);
            button {
              border-radius: 50%;
              text-align: center;
              width: 32px;
              height: 32px;
              line-height: 32px;
              padding: 0;
              &::before {
                color: var(--primaryClr);
                font-size: 15px;
                font-family: Arial, Helvetica, sans-serif;
                text-shadow: 1px 1px 3px #555;
                width: 32px;
                height: 32px;
                line-height: 30px;
                opacity: 1;
                transition: all 0.4s;
              }
            }
            &:first-child {
              button {
                &::before {
                  content: "01";
                }
              }
            }
            &:nth-child(2) {
              button {
                &::before {
                  content: "02";
                }
              }
            }
            &:nth-child(3) {
              button {
                &::before {
                  content: "03";
                }
              }
            }
            &.slick-active {
              button {
                background: var(--primaryClr);
                &::before {
                  color: var(--whiteClr);
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
    .casino_games {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(2, 1fr);
      a {
        position: relative;
        img {
          border-radius: 5px;
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(#3f3f4f 10%, #000 100%);
          color: var(--primaryClr);
          font-weight: 700;
          line-height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          opacity: 0;
          border-radius: 5px;
          .game_type {
            font-size: 10px;
            text-transform: uppercase;
            max-width: 90px;
            transition: all 0.4s;
          }
        }
        &:hover {
          .overlay {
            opacity: 0.8;
            .game_type {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    &.matched_bets {
      @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
        display: block;
      }
      @media only screen and (max-width: 991.98px) {
        display: none;
      }
      .accordion {
        .accordion-item {
          background-color: #fff;
          padding-bottom: 5px;
          margin-bottom: 10px;
          border: 0;
          border-radius: 5px;
          box-shadow: 0 0 10px rgb(0 0 0 / 60%);
          .accordion-header {
            .accordion-button {
              background: transparent;
              width: 100%;
              text-align: left;
              padding: 5px 10px;
              border: 0;
              // border-bottom: 1px solid var(--primaryClr);
              border-radius: 0;
              box-shadow: none;
              img {
                width: 20px;
                margin-right: 10px;
              }
              span {
                color: var(--primaryClr);
                font-size: 13px;
                font-weight: 600;
                line-height: 28px;
              }
              &:hover,
              &:focus {
                background: transparent !important;
              }
              &:after {
                content: none;
              }
            }
          }
          .accordion-body {
            color: var(--whiteClr);
            padding: 0;
            border: 0;
            .bets_box {
              .bet_info {
                .table {
                  background: #c3c3c3;
                  margin-bottom: 0;
                  border-collapse: collapse;
                  overflow-x: auto;
                  border: 1px solid #c3c3c3;
                  thead {
                    background: #c3c3c3;
                    display: block;
                    tr {
                      th {
                        color: var(--blackClr);
                        font-size: 12px;
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 150px;
                        border: 0;
                        &:not(:first-child) {
                          min-width: 60px;
                        }
                        &:first-child {
                          min-width: 35px;
                        }
                        &:last-child {
                          width: 100%;
                        }
                      }
                    }
                  }
                  tbody {
                    display: block;
                    overflow-y: auto;
                    overflow-x: hidden;
                    max-height: 360px;
                    &::-webkit-scrollbar {
                      width: 6px;
                      height: 6px;
                    }
                    &::-webkit-scrollbar-track {
                      background: #222;
                    }
                    &::-webkit-scrollbar-thumb {
                      background: #333;
                      &:hover {
                        background: #444;
                      }
                    }
                    tr {
                      td {
                        color: var(--blackClr);
                        font-size: 11px;
                        border-color: rgba(147, 146, 146, 0.6);
                        &:not(:first-child) {
                          min-width: 60px;
                        }
                        &:first-child {
                          min-width: 35px;
                        }
                        &:last-child {
                          width: 100%;
                        }
                        span {
                          color: var(--bodyClr);
                          width: 35px;
                          text-align: center;
                          display: inline-block;
                          border-radius: 4px;
                          &.lay {
                            background: #72bbef;
                          }
                          &.back {
                            background: #faa8ba;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .no_data {
              color: var(--blackClr);
              font-size: 20px;
              font-weight: 600;
              width: 100%;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            }
          }
          &.live_tv {
            .accordion-body {
              background: var(--bodyClr);
              .live_tv_box {
                height: 215px;
              }
            }
          }
          &.bets {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .match_slider_sec {
    width: calc(100% - 50px);
    margin-left: 25px;
    .match_info_slider {
      .info_box {
        background: #222430;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 128px;
        padding: 8px;
        margin: 0 5px;
        .match {
          text-align: center;
          font-size: 10px;
          line-height: 12px;
          .tournament {
            color: #abb0ba;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .time {
            color: var(--whiteClr);
            font-weight: 500;
          }
        }
        .participants {
          display: flex;
          position: relative;
          margin: 4px;
          color: var(--whiteClr);
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          .item {
            flex: 1;
            overflow: hidden;
            font-size: 10px;
            text-align: center;
            .flag {
              width: 28px;
              height: 28px;
              line-height: 28px;
              margin: 0 auto;
              border-radius: 50%;
            }
            .name {
              line-height: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .market {
          display: flex;
          gap: 4px;
          .outcome {
            background: #373d57;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            flex: 1;
            display: flex;
            justify-content: space-between;
            padding: 8px;
            border-radius: 4px;
            .name {
              color: #abb0ba;
            }
            .odds {
              color: #e7cf90;
            }
          }
        }
      }
      .slick-arrow {
        background: #222430;
        height: 100%;
        &.slick-prev {
          border-radius: 8px 0 0 8px;
        }
        &.slick-next {
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }
}
.sports_page,
.custom-tabs {
  padding-top: 10px;
  .sports-name-listing {
    ul {
      flex-wrap: nowrap;
      padding: 10px;
      background: var(--secondaryClr);
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      li {
        margin: 0 5px;
        a,
        div {
          color: #fff;
          padding: 5px;
          display: block;
          cursor: pointer;
          &:hover,
          &.active {
            background: transparent;
            color: var(--primaryClr);
          }
        }
      }
    }
  }
  .nav {
    flex-wrap: nowrap;
    background: var(--whiteClr);
    // margin-bottom: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;
    .nav-item {
      margin: 0 5px;
      .nav-link {
        color: var(--blackClr);
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 5px;
        border: none;
        border-radius: 0;
        &:hover,
        &.active {
          background: #e1e1e1;
          color: var(--primaryClr);
        }
      }
    }
  }
  .tab-pane {
    .title {
      background: var(--headClr);
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      display: none;
      @media only screen and (max-width: 767.98px) {
        margin-bottom: 0;
        justify-content: center;
      }
      span {
        font-size: 14px;
        padding: 6px 1rem;
        display: inline-block;
        color: var(--whiteClr);
        margin-left: -1rem;
      }
    }
    .game-listing {
      background: var(--secondaryClr);
      border: 1px solid #c3c3c3;
      border-radius: 0 0 0.5rem 0.5rem;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.28);

      .match-listing-head {
        display: none;
        ul {
          background-color: #ced5da;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          li:first-child {
            width: calc(100% - 300px);
            display: block;
          }
          li {
            width: 48px;
            color: #000;
            padding: 5px 3px;
            font-size: 12px;
          }
        }
      }
      .match-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 6px;
        border-bottom: 1px solid rgb(147 146 146 / 60%);
        background: #c3c3c3;
        // height: 25px;
        &:last-child {
          border-bottom: 0;
          border-radius: 0 0 5px 5px;
        }
        .live-match {
          height: 8px;
          width: 8px;
          margin-right: 5px;
          background: #c4cfd6;
          border: 1px solid #366601;
          border-radius: 50%;
        }
        .live-match.active {
          background: #6aba12;
          border: 1px solid #366601;
        }
        .match-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: calc(100% - 315px);
          padding: 5px 0;
          .breadcrumb {
            font-size: 12px;
            display: flex;
            align-items: center;
            font-weight: 700;
            color: #000000;
            cursor: pointer;
            .date_time_info {
              background: #36404d;
              width: 68px;
              height: 42px;
              overflow: hidden;
              margin-right: 10px;
              padding: 6px 1px;
              border-radius: 5px;
              position: relative;
              .date {
                color: var(--whiteClr);
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                text-align: center;
                text-transform: uppercase;
              }
              &.in-play {
                background: var(--headClr);
                .date {
                  color: var(--primaryClr) !important;
                }
                span {
                  position: absolute;
                  background: linear-gradient(
                    to right,
                    #171618,
                    var(--primaryClr)
                  );
                  &:nth-child(1) {
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    animation: animate1 2s linear infinite;
                    @keyframes animate1 {
                      0% {
                        transform: translateX(-100%);
                      }
                      100% {
                        transform: translateX(100%);
                      }
                    }
                  }
                  &:nth-child(2) {
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 1px;
                    animation: animate2 2s linear infinite;
                    animation-delay: 1s;
                    @keyframes animate2 {
                      0% {
                        transform: translateY(-100%);
                      }
                      100% {
                        transform: translateY(100%);
                      }
                    }
                  }
                  &:nth-child(3) {
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    animation: animate3 2s linear infinite;
                    @keyframes animate3 {
                      0% {
                        transform: translateX(100%);
                      }
                      100% {
                        transform: translateX(-100%);
                      }
                    }
                  }
                  &:nth-child(4) {
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 1px;
                    animation: animate4 2s linear infinite;
                    animation-delay: 1s;
                    @keyframes animate4 {
                      0% {
                        transform: translateY(100%);
                      }
                      100% {
                        transform: translateY(-100%);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .status {
          background: var(--oddsBack);
          color: var(--blackClr);
          display: flex;
          padding: 0 3px;
          font-size: 11px;
          font-style: italic;
          font-weight: 600;
          border-radius: 2px;
          align-items: center;
          justify-content: center;
        }
        .listing {
          display: flex;
          flex-wrap: nowrap;
          li {
            margin-right: 5px;
            width: 43px;
            height: 30px;
            &:nth-child(2),
            &:nth-child(4) {
              margin-right: 10px;
            }
            &:last-child {
              margin-right: 0;
            }
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              padding: 0 2px;
              cursor: pointer;
              height: 30px;
              line-height: 30px;
              border-radius: 5px;
              span {
                color: var(--blackClr);
                font-size: 12px;
                font-weight: 700;
              }
              &.yes {
                background: var(--oddsBack);
              }
              &.no {
                background: var(--oddsLay);
              }
            }
          }
        }
      }
    }
    .top-row {
      display: flex;
      justify-content: space-between;
      background: var(--headClr);
      margin: 20px -1rem;
      padding: 0 1rem;
      .bet-btns {
        display: flex;
        li {
          background: var(--primaryClr);
          color: var(--whiteClr);
          margin: 4px 0px 4px 4px;
          text-transform: uppercase;
          font-size: 11px;
          padding: 4px 8px;
          border-radius: 2px;
        }
      }
    }
    .status-btns {
      display: flex;
      align-items: center;
      .info-block {
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--whiteClr);
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
      }
    }
    .game-points {
      ul {
        margin: 0 -1rem;
        li {
          display: flex;
          border-bottom: 1px solid var(--secondaryClr);
          padding: 3px 1rem;
          align-items: center;
          font-size: 11px;
          .team-name {
            display: flex;
            flex-direction: column;
            line-height: normal;
            .time {
              color: var(--siteRedClr);
            }
          }
          .score {
            margin-left: auto;
          }
          .status-btns {
            margin-left: 5px;
            position: relative;
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 8px;
              line-height: normal;
              span {
                font-size: 12px;
              }
            }
            .points-status {
              position: absolute;
              top: 4px;
              left: 4px;
              width: 95%;
              background: rgba(0, 0, 0, 0.7);
              font-size: 12px;
              color: var(--whiteClr);
              text-align: center;
              border-radius: 2px;
              height: 80%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .game-top-area {
      background: var(--headClr);
      margin: 0 -1rem;
      padding: 10px 1rem;
      .date {
        text-align: center;
        color: var(--primaryClr);
        font-size: 12px;
        margin-bottom: 5px;
      }
      .title {
        margin: 0;
        padding: 0;
        font-size: 14px;
      }
      .range {
        background: var(--primaryClr);
        font-size: 11px;
        color: var(--blackClr);
        padding: 2px 5px;
        line-height: normal;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.sports_page {
  .tab-content {
    .tab-pane {
      max-height: calc(100vh - 120px);
      padding-bottom: 10px;
      overflow-y: auto;
    }
  }
}
.match_details_container {
  background: #c3c3c3;
  padding-top: 15px;
  .math_info {
    text-align: center;
    .match_type {
      background: var(--primaryClr);
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      text-shadow: 1px 1px 3px rgb(0 0 0 / 50%);
      word-spacing: 3px;
      width: fit-content;
      height: 34px;
      line-height: 34px;
      padding: 0 15px;
      margin: 0 auto;
      border-radius: 25px;
      position: relative;
      z-index: 1;
      animation: shine 3s linear infinite;
      @keyframes shine {
        0% {
          -webkit-box-shadow: 0 0 0 0 var(--primaryClr);
          box-shadow: 0 0 0 0 var(--primaryClr);
        }
        40% {
          -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
        }
        80% {
          -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
        }
        100% {
          -webkit-box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
        }
      }
      &:after {
        position: absolute;
        content: "";
        top: 16px;
        left: -40%;
        background: var(--primaryClr);
        width: 180%;
        height: 2px;
        z-index: -1;
        @media screen and (max-width: 767px) {
          width: 140%;
          left: -20%;
        }
      }
    }
    .team_names {
      margin: 15px 0 10px;
      span {
        color: var(--primaryClr);
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        @media screen and (max-height: 575.98px) {
          font-size: 13px;
        }
        span {
          color: rgb(237, 33, 58);
          font-size: 12px;
          @media screen and (max-height: 575.98px) {
            font-size: 10px;
          }
        }
        &:last-child {
          span {
            display: none;
          }
        }
      }
    }
    .collapse_container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
      .btn {
        background: linear-gradient(
          to right,
          #1186d3 0,
          #41aaf0 15%,
          #41aaf0 30%,
          #1186d3 55%,
          #1186d3 100%
        ) !important;
        border: 0;
        border-radius: 5px;
        padding: 4px 6px;
        &:hover,
        &:focus {
          background: linear-gradient(
            to right,
            #1186d3 0,
            #41aaf0 15%,
            #41aaf0 30%,
            #1186d3 55%,
            #1186d3 100%
          ) !important;
        }
        img {
          width: 14px;
        }
      }
      .in_play {
        background: #fff;
        color: #41aaf0;
        text-transform: uppercase;
        letter-spacing: 2px;
        overflow: hidden;
        font-weight: 700;
        margin: 0 15px;
        padding: 2px 6px;
        position: relative;
        .anim_line1 {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(to right, #171618, #1186d3);
          animation: animate1 2s linear infinite;
        }
        .anim_line2 {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 1px;
          background: linear-gradient(to bottom, #171618, #1186d3);
          animation: animate2 2s linear infinite;
          animation-delay: 1s;
        }
        .anim_line3 {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(to left, #171618, #1186d3);
          animation: animate3 2s linear infinite;
        }
        .anim_line4 {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 1px;
          background: linear-gradient(to top, #171618, #1186d3);
          animation: animate4 2s linear infinite;
          animation-delay: 1s;
        }
      }
    }
  }
  .sports-match-iframe {
    height: 100%;
    @media screen and (min-width: 1400px) {
      height: 390px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1399.98px) {
      height: 330px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1199.98px) {
      height: 361px;
    }
    @media screen and (min-width: 992px) and (max-width: 1023.98px) {
      height: 311px;
    }
    @media screen and (min-width: 880px) and (max-width: 991.98px) {
      height: 391px;
    }
    @media screen and (min-width: 768px) and (max-width: 879.98px) {
      height: 361px;
    }
    @media screen and (min-width: 650px) and (max-width: 767.98px) {
      height: 321px;
    }
    @media screen and (min-width: 576px) and (max-width: 649.98px) {
      height: 291px;
    }
    @media screen and (min-width: 480px) and (max-width: 575.98px) {
      height: 381px;
    }
    @media screen and (min-width: 420px) and (max-width: 479.98px) {
      height: 350px;
    }
    @media screen and (min-width: 380px) and (max-width: 419.98px) {
      height: 330px;
    }
    @media screen and (min-width: 360px) and (max-width: 379.98px) {
      height: 310px;
    }
    @media screen and (min-width: 320px) and (max-width: 359.98px) {
      height: 290px;
    }
    @media screen and (max-width: 319.98px) {
      height: 280px;
    }
  }
}
.page-details {
  .game-points {
    padding-bottom: 0;
  }
  .top-row {
    display: flex;
    justify-content: space-between;
    background: #c3c3c3;
    margin-bottom: 10px;
    padding: 0.5rem 1rem;
    ul.bet-btns {
      display: flex;
      li {
        background: var(--primaryClr);
        color: var(--whiteClr);
        margin: 4px 0px 4px 4px;
        text-transform: uppercase;
        font-size: 11px;
        padding: 4px 8px;
        border-radius: 2px;
      }
    }
  }
  .game-top-area {
    background: #c3c3c3;
    margin-bottom: 0px;
    padding: 5px 1rem 5px 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .date {
      text-align: center;
      color: var(--blackClr);
      font-size: 12px;
      margin-bottom: 5px;
      order: 2;
      margin-bottom: 0;
    }
    .title {
      background: transparent;
      margin: 0;
      padding: 0;
      font-size: 14px;
      line-height: 30px;
      color: #000;
      font-weight: bold;
    }
    .range {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      float: none;
      padding: 0 6px;
      margin: 7px 0;
      background: var(--primaryClr);
      font-size: 12px;
      line-height: 16px;
      border-radius: 3px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .title {
    background: #c3c3c3;
    padding: 0 1px 0 1rem;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 767.98px) {
      background-color: #3b5160;
      margin-bottom: 0;
      margin-top: 0 !important;
      padding: 0 0.25rem 0 0.5rem;
    }
    span {
      font-size: 14px;
      padding: 6px 0;
      display: inline-block;
      color: var(--whiteClr);
    }
    .status-btns {
      display: flex;
      align-items: center;
      @media screen and (max-width: 767.98px) {
        width: 136px;
      }
      .info-block {
        font-size: 13px;
        font-weight: 600;
        color: var(--blackClr);
        text-align: center;
        width: 68px;
        height: 26px;
        line-height: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2px 1px 1px;
        padding: 3px 2px;
        border-radius: 2px;
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
        @media screen and (max-width: 767.98px) {
          width: 50%;
        }
      }
    }
  }
  .game-points {
    border: 1px solid #c3c3c3;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.28);
    padding-bottom: 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    ul {
      li {
        display: flex;
        border-bottom: 1px solid rgba(147, 146, 146, 0.6);
        padding: 1px 0 1px 0.75rem;
        align-items: center;
        .score {
          margin-left: auto;
          margin-right: 5px;
        }
        .team-name {
          font-size: 12px;
          font-weight: 700;
          line-height: normal;
          display: flex;
          flex-direction: column;
          .time {
            color: var(--siteRedClr);
          }
        }
        .status-btns {
          display: flex;
          align-items: center;
          position: relative;
          .points-status {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            text-shadow: 0 0.26667vw 1.06667vw rgb(0 0 0 / 50%);
            font-size: 12px;
            color: var(--primaryClr);
            text-align: center;
            border-radius: 5px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &::after {
              position: absolute;
              top: 7px;
              left: 5px;
              border: 1px solid var(--primaryClr);
              content: "";
              padding: 10px;
              width: calc(100% - 10px);
              height: 24px;
              border-radius: 5px;
            }
          }
          &.match_odds {
            .info-block {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(5),
              &:nth-child(6) {
                background: #e9e9e9;
              }
            }
          }
        }
        .info-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 8px;
          line-height: normal;
          font-size: 10px;
          margin: 2px 1px 1px;
          padding: 5px 2px;
          width: 68px;
          text-align: center;
          color: var(--blackClr);
          cursor: pointer;
          border-radius: 4px;
          &.yes {
            background: var(--oddsBack);
          }
          &.no {
            background: var(--oddsLay);
          }
          span {
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.sports_page_banner .slick-slider .slick-dots {
  bottom: 8px;
}
.sports_page_banner .slick-slider .slick-dots li {
  height: 3px;
}
.sports_page_banner .slick-slider .slick-dots li button {
  height: 3px;
  padding: 0;
}
.sports_page_banner .slick-slider .slick-dots li button:before {
  background: hsla(0, 0%, 100%, 0.7);
  color: transparent;
  height: 3px;
  opacity: 1;
  width: 25px;
}
.sports_page_banner .slick-slider .slick-dots li.slick-active button:before {
  background-color: var(--primaryClr);
}
.betslip_popup {
  .modal-body {
    background: #fff;
    input {
      background-color: #c3c3c3 !important;
      border-color: #c3c3c3 !important;
    }
    .stack-win-value {
      flex-wrap: wrap;
      div {
        width: 46%;
      }
    }
    .auto-stake {
      ul {
        li {
          .stack-btn {
            background: var(--secondaryClr);
            color: var(--whiteClr);
          }
        }
      }
    }
  }
}
.gatewaySection {
  max-width: 800px;
  margin: 40px auto 15px;
  ul.nav-tabs {
    border: 0;
    margin-bottom: 0 !important;
    li {
      width: 50%;
      border-right: 1px solid white;
      button {
        background: #c3c3c3;
        // border-radius: 15px 15px 0px 0px;
        text-transform: uppercase;
        border: 0;
        border-radius: 0;
        width: 100%;
        font-size: 1rem;
        font-weight: 700;
        color: #000000;
        padding: 14px 10px;
        &.active {
          background: linear-gradient(
            0deg,
            rgb(46, 83, 19) 0%,
            rgb(145, 175, 121) 100%
          );
          // border-radius: 15px 15px 0px 0px;
          color: #fff;
        }
      }
    }
  }
  .tab-content {
    background: #e1e1e1;
    padding: 20px 50px;
    .tab-pane.active {
      display: flex;
      flex-wrap: wrap;
      .cards1 {
        cursor: pointer;
        // background: linear-gradient(0deg, #031c2a 0%, #213040 100%);
        border: 0.5px solid #64c120;
        box-sizing: border-box;
        border-radius: 10px;
        width: 31.3333%;
        float: left;
        margin: 1% 1%;
        .card-icon {
          padding: 12px 5px 4px 5px;
          font-size: 12px;
          border-bottom: 0.5px solid #64c120;
          text-align: center;
          img {
            width: 80px;
          }
        }
        p {
          padding: 11px 4px;
          font-size: 1rem;
          font-weight: 500;
          margin: 0;
          text-align: center;
        }
      }
    }
  }
}
.gatewayForm {
  margin: 25px;

  input {
    border-color: #4a5d6b;
    padding: 14px 20px;
  }
  button {
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 10px;
    min-width: 120px;
    padding: 9px 30px;
    text-transform: uppercase;
  }
}

.heading {
  display: flex;
  justify-content: space-between;
  // background: var(--blackClr);
  background: linear-gradient(
    0deg,
    rgb(46, 83, 19) 0%,
    rgb(145, 175, 121) 100%
  );
  padding: 10px;
  align-items: center;
  color: #fff;
  h4 {
    margin: 0;
  }
}
.valuesDeposit {
  border: 1px var(--bs-primary) solid;
  margin-top: 20px;
  color: var(--bs-primary);
  background: #ffffff;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  /* width: 50%; */
  justify-content: center;
  h3 {
    font-weight: 400;
  }
  .copyValue {
    font-size: 20px;
    margin-left: 30px;
    text-align: center;
    div {
      font-size: 8px;
      font-weight: 700;
    }
  }
}
.qrSize {
  width: 250px;
}
.new_table {
  thead {
    color: #fff;
    th {
      padding: 5px;
    }
    background: var(--secondaryClr2);
  }
  tbody {
    background: #2b2d30;
    color: #000000;
    tr td {
      border: 0;
    }
  }
  background: var(--headClr);
  padding: 6px;
}
.primary_card {
  border-radius: 10px;
  border: 1px solid #53763a;
  .stats-card {
    display: flex;
    ul {
      border-right: 1px solid #53763a;
      width: 50%;
      &:last-child {
        border: 0;
      }
      li {
        // height: 50px;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #53763a;
        font-weight: 700;
        span {
          width: 50%;
          // font-size: 13px;
          display: block;
          font-weight: normal;
        }
      }
    }
  }
}
.main_card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .progress {
    background: #393939;
  }
}
.cards {
  background: #fff;
  span {
    color: #628449;
    margin-left: 4px;
  }
  padding: 15px;
  border-radius: 10px;
  width: 32.33%;
  margin: 0.5%;
  // background: url(./assets/images/background.jpg);
  box-shadow: 0px 1px 10px 3px #3a3a3a70;
}
.amount_data {
  justify-content: space-between;
  text-align: center;
  align-items: center;
}
.amount_data {
  .status {
    color: green;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
  .status_claimed {
    color: #f1c232;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
  .staus_pending {
    color: #f1c232;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
  .staus_expired {
    color: #cc0000;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
  .staus_canceled {
    color: #cc0000;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
}
.cancel_bonus {
  background: red;
  padding: 2px 4px;
  font-size: smaller;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 5px;
  text-transform: uppercase;
}
.cancel_bonus_modal {
  .modal-dialog {
    .modal-content {
      background: #fff;
      color: #000;
    }
  }
}
.referral_card_main {
  display: flex;
  justify-content: normal !important;
  margin-top: 10px !important;
  gap: 15px;
  .referrals_cards {
    margin-top: 10px;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    flex: 1 1;
    h5 {
      font-size: 1rem;
    }
    .referr_user {
      display: flex;
      gap: 10px;
      svg {
        fill: var(--primaryClr);
      }
      p {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }
}
.referral_link {
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 20px;
  h5 {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
    margin-right: 20px;
  }
  div {
    background: #e1e1e1;
    border-radius: 5px;
    color: #000;
    flex: 1 1;
    padding: 8px 15px;
  }
}
.referr_tabs ul {
  border-bottom: 0;
  gap: 20px;
  button {
    background: #e8a70a !important;
    border: 0 !important;
    color: #fff !important;
    padding: 8px 35px;
  }
}
.referr_table {
  color: #fff;
  thead {
    background: #6d8e54;
    border: 0;
    th {
      border: 0;
    }
  }
  tbody {
    background-color: #e1e1e1;
    color: #000;
    td {
      border-color: #333;
    }
  }
}

.WithdrawPageSection {
  .WithDrawSection.tabs-area {
    width: 760px;
    ul {
      border: 0;
      background: #2c2f3d;
      margin: 0;
      li {
        button {
          color: var(--whiteClr) !important;
          border-radius: 0 !important;
          text-align: center;
          font-weight: 400;
          border: 1px solid #444;
          background: rgb(44, 47, 61);
          background: linear-gradient(
            100deg,
            rgba(44, 47, 61, 1) 0%,
            rgba(57, 67, 91, 1) 100%
          );
          padding: 10px !important;
          &.active {
            background: rgb(6, 99, 177);
            background: linear-gradient(
              100deg,
              rgb(43 133 243) 0%,
              rgb(131 175 228) 100%
            ) !important;
          }
        }
      }
    }
    .tab-content {
      padding-bottom: 0 !important;

      .WithDrawBankDetails {
        background-color: #021622;
        background-color: #021622;
        background: url("./assets/images/banner/withdrawbackground.jpg") #021622;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 10px;
        .heading {
          display: none;
        }
        .playerBankDetails {
          input,
          select {
            height: 45px;
            border-radius: 3px !important;
          }
          input {
            background: transparent !important;
            color: var(--whiteClr) !important;
            border: 1px solid var(--whiteClr) !important;
            &::placeholder {
              color: var(--whiteClr);
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
              -webkit-text-fill-color: var(--blackClr) !important;
            }
          }
          button.btn-primary {
            background: rgb(6, 99, 177);
            background: linear-gradient(
              100deg,
              rgba(6, 99, 177, 1) 0%,
              rgba(40, 131, 239, 1) 100%
            );
            border-radius: 5px !important;
            border: 0;
            font-weight: 400;
            height: 45px;
          }
        }
        h4 {
          color: var(--whiteClr);
          font-size: 18px;
          margin-bottom: 15px;
        }
        .WithdrawTable {
          thead {
            border: 1px solid #7f898f;
            border-radius: 3px !important;
            th {
              white-space: nowrap;
            }
          }
          tbody {
            tr {
              td {
                color: var(--whiteClr) !important;
                padding: 12px 0 !important;
                line-height: 30px;
                input[type="checkbox"] {
                  display: none;
                }
                label {
                  display: block;
                  margin: 0.2em;
                  cursor: pointer;
                  padding: 0.2em;
                }
                button {
                  margin-right: 8px;
                }
              }
              th,
              td {
                color: var(--whiteClr) !important;
                padding: 12px !important;
                line-height: 30px;
                background: transparent !important;
                white-space: nowrap;
              }
            }
          }
        }
      }

      .WithdrawRequestSection {
        ul.nav-tabs {
          margin-top: 10px;
          background-color: var(--whiteClr) !important;
          button {
            background: var(--whiteClr) !important;
            color: var(--blackClr) !important;
            text-align: center !important;
            &.active {
              background: #169f00 !important;
              color: var(--whiteClr) !important;
            }
          }
        }
        .tab-content {
          background: url("./assets/images/banner/withdrawbackground.jpg")
            #021622;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          padding: 10px;
          input {
            background: transparent !important;
            color: var(--whiteClr) !important;
            border: 1px solid var(--whiteClr) !important;
            &::placeholder {
              color: var(--whiteClr);
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
              -webkit-text-fill-color: var(--blackClr) !important;
            }
          }
        }

        .WithdrawRequestBankSection {
          background-color: #021622;
          padding: 10px;
          .WithdrawTable {
            border: 0;
            thead {
              background-color: #2c2f3d;
              border: 0;
              border-radius: 3px !important;
              th {
                border: 0;
                color: var(--whiteClr);
                white-space: nowrap;
                background: transparent;
              }
            }
            tbody {
              tr {
                background: transparent;
                th {
                  background: transparent;
                  line-height: 40px;
                  color: var(--whiteClr);
                }
                td {
                  color: var(--whiteClr) !important;
                  padding: 12px 0.5rem !important;
                  line-height: 30px;
                  background: transparent;
                  button {
                    margin-right: 8px !important;
                  }
                }
              }
            }
          }
        }
        .sectionTitle {
          color: var(--whiteClr) !important;
          margin: 18px 0 25px !important;
          position: relative;
          text-align: center !important;
          &::after {
            position: absolute;
            content: "";
            border: 1px solid var(--whiteClr);
            left: 50%;
            transform: translate(-50%, -50%);
            width: 120px;
            bottom: 0;
          }
        }
        button.btn-primary {
          background: rgb(6, 99, 177);
          background: linear-gradient(
            100deg,
            rgba(6, 99, 177, 1) 0%,
            rgba(40, 131, 239, 1) 100%
          );
          border-radius: 5px !important;
          border: 0;
          font-weight: 400;
          height: 45px;
        }
      }
    }
  }
}

.updateBankDeatils {
  z-index: 999999;
  .modal-header {
    background-color: var(--headClr);
    color: var(--whiteClr);
    border: 0;
    button {
      filter: invert(1);
    }
  }
  .modal-body {
    background: #0b3147;
    input {
      background: transparent !important;
      color: var(--whiteClr) !important;
      border: 1px solid var(--whiteClr) !important;
      &::placeholder {
        color: var(--whiteClr);
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
        -webkit-text-fill-color: var(--blackClr) !important;
      }
    }
    button.btn-primary {
      background: rgb(6, 99, 177);
      background: linear-gradient(
        100deg,
        rgba(6, 99, 177, 1) 0%,
        rgba(40, 131, 239, 1) 100%
      );
      border-radius: 5px !important;
      border: 0;
      font-weight: 400;
      height: 35px;
      margin: 0 auto;
      width: 85px;
      text-align: center;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.WithdrawGif {
  img {
    animation: moving 3s infinite linear;
    margin-left: 20px;
    width: 365px;
    @keyframes moving {
      0% {
        left: 0;
      }
      50% {
        left: 0;
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
      }
      100% {
        left: 0;
      }
    }
  }
}

.WithdrawTable tbody tr td input[type="checkbox"] + label {
  display: block;
  margin-bottom: 0;
  cursor: pointer;
  padding: 0.2em;
}
.WithdrawTable tbody tr td input[type="checkbox"] {
  display: none;
}
.WithdrawTable tbody tr td input[type="checkbox"] + label:before {
  content: "\2714";
  border: 0.1em solid #fff;
  border-radius: 0.2em;
  display: inline-block;
  width: 2em;
  height: 2em;
  padding-left: 0.5em;
  padding-bottom: 0.3em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
}
.WithdrawTable tbody tr td input[type="checkbox"]:checked + label:before {
  background-color: MediumSeaGreen;
  border-color: MediumSeaGreen;
  color: #fff;
}

.WithdrawPageSection
  .WithDrawSection.tabs-area
  .tab-content
  .WithdrawRequestSection
  .tab-content
  input:disabled {
  background: #415059 !important;
  border: 0 !important;
  cursor: no-drop !important;
}

.HeadingSection {
  background-color: #1e1e1e;
  padding: 8px;
  height: 50px;
  align-items: center;
  .sectionTitle {
    margin: 0;
    padding: 0;
    &::after {
      display: none;
    }
  }
}
.BankDetailsBox {
  background-color: var(--secondaryClr);
  margin-top: 1rem;
  padding: 10px;
  p {
    margin-bottom: 8px;
    &:last-child {
      margin: 0;
    }
  }
}

table.WithdrawList {
  thead {
    background-color: var(--secondaryClr);
  }
  tbody {
    background-color: #1e1e1e;
  }
}

.RightBarHead {
  h2 {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
  }
}
